import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import fetchData from 'utils/fetchHelper';
import { AlertContent, AlertTitle } from 'assets/styles/sharedStyles';
import { setLoyaltyUser } from 'actions/loyalty';

import AuraUserCard from './user-card';
import AuraSignInPage from './sign-in';
import AuraSplashPage from './splash-page';
import AuraSignOut from './sign-out';
import OTPForm from './OTPForm';

import { messages } from './messages';
import { Container, StyledActionButton } from './styles';

export const StyledTitle = styled(AlertTitle)`
  padding: 20px 0;
  background-color: #418613;
  color: white;
`;

const AuraContent = styled(AlertContent)`
  background-color: white;
  color: #5e6d70;
`;

const AccountLinkSuccessContainer = styled.div`
  padding: 1rem 0;

  p {
    padding: 0.5rem 0;
  }

  p:last-child {
    color: ${({ theme }) => theme.color.brandSecondary};
  }
`;

const AuraModal = ({ closeAlert = () => {}, user }) => {
  const dispatch = useDispatch();

  const pages = {
    splash: {
      content: AuraSplashPage,
    },
    signIn: {
      title: <FormattedMessage {...messages.earnWithAura} />,
      content: AuraSignInPage,
      container: AuraContent,
    },
    signInSuccess: {
      title: <FormattedMessage {...messages.congratulations} />,
      content: () => (
        <AuraContent>
          <Container>
            <FormattedMessage {...messages.buildYourAura} />
          </Container>
        </AuraContent>
      ),
      container: AuraContent,
    },
    userCard: {
      content: AuraUserCard,
    },
    signOut: {
      title: <FormattedMessage defaultMessage="Sign Out" />,
      content: AuraSignOut,
      container: AuraContent,
    },
    signOutConfirmation: {
      title: <FormattedMessage defaultMessage="Signed Out" />,
      content: () => (
        <Container>
          <FormattedMessage defaultMessage="You've successfully signed out of your Aura account." />
        </Container>
      ),
      container: AuraContent,
    },
    linkAccount: {
      title: <FormattedMessage defaultMessage="Link your Aura" />,
      content: () => (
        <OTPForm
          defaultMode="send-otp"
          onSuccess={() => {
            fetchData('/api/loyalty/link', 'POST', {}, response => {
              if (response.success) {
                dispatch(setLoyaltyUser(response.user));
                setPage('linkAccountSuccess');
              } else {
                setPage('linkAccountError');
              }
            });
          }}
        />
      ),
    },
    linkAccountSuccess: {
      title: <FormattedMessage defaultMessage="Account Linked" />,
      content: () => (
        <AccountLinkSuccessContainer>
          <p>
            <FormattedMessage defaultMessage="You can now start building your Aura. Earn points every time you shop online or instore." />
          </p>

          <p>
            <FormattedMessage defaultMessage="Use your points on future purchases or to access a range of rewards and experiences on the Aura app." />
          </p>
        </AccountLinkSuccessContainer>
      ),
      container: AuraContent,
    },
    linkAccountError: {
      title: <FormattedMessage defaultMessage="Account Linking Error" />,
      content: () => (
        <AccountLinkSuccessContainer>
          <p>
            <FormattedMessage defaultMessage="There was an error linking your account. Please try again." />
          </p>
          <p>
            <StyledActionButton onClick={() => setPage('linkAccount')}>
              <FormattedMessage defaultMessage="Try Again" />
            </StyledActionButton>
          </p>
        </AccountLinkSuccessContainer>
      ),
      container: AuraContent,
    },
  };

  const [page, setPage] = useState(user ? 'userCard' : 'splash');

  const PageContent = pages[page].content;
  const PageContainer = pages[page].container || AlertContent;

  return (
    <>
      {pages[page].title && <StyledTitle>{pages[page].title}</StyledTitle>}
      <PageContainer>
        <PageContent setPage={setPage} closeAlert={closeAlert} user={user} />
      </PageContainer>
    </>
  );
};

AuraModal.propTypes = {
  closeAlert: PropTypes.func,
  acceptTermsAction: PropTypes.func,
  confirmMethod: PropTypes.func,
  user: PropTypes.shape({
    points: PropTypes.number,
    accountNo: PropTypes.string,
    tier: PropTypes.string,
  }),
};

export default connect(state => ({
  user: state.getIn(['loyalty', 'user'])?.toJS(),
}))(AuraModal);
