import React from 'react';
import { useLoyalty } from 'hooks';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import Tooltip from 'components/Tooltip';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CAPABILITY_CALCULATE_POINTS } from 'actions/loyalty/constants';
import {
  Container,
  InfoContainer,
  LinkText,
  NoFundsLeft,
  PointsRemaining,
  RewardText,
  StyledLogo,
  StyledLogoWrapper,
  StyledText,
  Question,
} from './styles';
import { openLoyaltyModal } from '../../../actions/loyalty';

export const Logo = () => (
  <StyledLogoWrapper>
    <StyledLogo fullColor={true} />
  </StyledLogoWrapper>
);

const EarnableLoyaltyPoints = () => {
  const loyalty = useLoyalty();
  const dispatch = useDispatch();
  const handleSignIn = () => dispatch(openLoyaltyModal());

  if (!loyalty.type) return null;
  if (!loyalty.capabilities?.includes(CAPABILITY_CALCULATE_POINTS)) return null;

  return (
    <Container>
      <InfoContainer>
        <Logo />

        {loyalty.loading && <LoadingSpinner />}
        {!loyalty.loading && (
          <>
            <StyledText>
              {!loyalty.earnableError && (
                <FormattedMessage
                  defaultMessage="{
                  signedIn, select,
                    true {{
                      points, plural,
                      one {Earn <green><b>#</b> point with {loyaltyType}</green> on this purchase.}
                      other {Earn <green><b>#</b> points with {loyaltyType}</green> on this purchase.}
                    }}
                    other {{
                      points, plural,
                      =0 {<green><b><a>Sign in</a></b></green> to earn points on this purchase.}
                      one {<green><b><a>Sign in</a></b></green> to earn <b>#</b> point on this purchase.}
                      other {<green><b><a>Sign in</a></b></green> to earn <b>#</b> points on this purchase.}
                    }}
                  }"
                  values={{
                    b: chunks => <strong>{chunks}</strong>,
                    green: chunks => <RewardText>{chunks}</RewardText>,
                    a: chunks => <LinkText onClick={handleSignIn}>{chunks}</LinkText>,
                    points: loyalty.earnable || 0,
                    loyaltyType: loyalty.type.charAt(0).toUpperCase() + loyalty.type.slice(1),
                    signedIn: !!loyalty.user,
                  }}
                />
              )}
              {loyalty.earnableError && loyalty.earnableError}
            </StyledText>
            <Tooltip
              text={
                loyalty.user ? (
                  <FormattedMessage defaultMessage="Your points will be credited to your Aura account. You will be able to redeem these a day after your order is delivered" />
                ) : (
                  <FormattedMessage defaultMessage="Join Aura, our loyalty programme, to enjoy earning and spending points every time you shop and unlock exclusive benefits." />
                )
              }
            >
              <Question>
                <FormattedMessage defaultMessage="?" />
              </Question>
            </Tooltip>
          </>
        )}
      </InfoContainer>
      {loyalty.user && (
        <>
          <PointsRemaining>
            <FormattedMessage defaultMessage="Current Points" />:{' '}
            {!loyalty.user?.points && <NoFundsLeft>({loyalty.user?.points})</NoFundsLeft>}
            {loyalty.user?.points && `(${loyalty.user?.points})`}
          </PointsRemaining>
        </>
      )}
    </Container>
  );
};

export default EarnableLoyaltyPoints;
