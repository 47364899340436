import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  OTP_COULD_NOT_SEND: {
    defaultMessage: 'We could not send the OTP. Please try again.',
  },
  OTP_INVALID_METHOD: {
    defaultMessage: 'The selected method is invalid for sending an OTP.',
  },
  OTP_INVALID_PHONE_NUMBER: {
    defaultMessage: 'The phone number you provided is invalid.',
  },
  OTP_INVALID_TOKEN: {
    defaultMessage: 'The OTP you entered is invalid.',
  },
  OTP_RATE_LIMIT_EXCEEDED: {
    defaultMessage: 'Too many requests have been made. Please try again shortly.',
  },
  OTP_TOKEN_EXPIRED: {
    defaultMessage: 'The OTP has expired. Please request a new one.',
  },
  OTP_UNKNOWN_ERROR: {
    defaultMessage: 'An unknown error occurred. Please try again later.',
  },
  UNSUPPORTED_DIALING_CODE: {
    defaultMessage: 'The phone number you provided is not supported.',
  },
});
