import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { selectLogoUrl, selectOrganisationName } from 'selectors/root';
import { selectLoyaltyWasAutoLogin } from 'selectors/loyalty';
import AuraPointsExpiry from 'components/Loyalty/RedeemLoyaltyPoints/AuraPointsExpiry';
import { Container, SignInOrOutButton, TitleInContent, BrandText, ModalFooter } from './styles';
import { FooterItem } from './components/FooterItem';
import LinkAuraBanner from './LinkAuraBanner';

const formatAccountNo = accountNo => accountNo.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4');

const AuraUserCard = ({ setPage, user }) => {
  const logoUrl = useSelector(selectLogoUrl);
  const companyName = useSelector(selectOrganisationName);
  const wasAutoLogin = useSelector(selectLoyaltyWasAutoLogin);

  return (
    <Container>
      <TitleInContent>
        <img src={logoUrl} alt={`${companyName} Logo`} width="120rem" />
      </TitleInContent>
      <div style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '0.9rem' }}>
        <BrandText>
          <FormattedMessage defaultMessage="Hello, {firstName}" values={{ firstName: user.firstName }} />
        </BrandText>

        <br />
        <FormattedMessage defaultMessage="You are signed into Aura" />

        <BrandText style={{ fontSize: '1.4rem' }} dir="ltr">
          {formatAccountNo(user.accountNo)}
        </BrandText>
      </div>
      <ModalFooter>
        <FooterItem title={<FormattedMessage defaultMessage="Points Balance" />}>
          {user.points} pts
        </FooterItem>

        <FooterItem title={<FormattedMessage defaultMessage="Your Tier" />}>{user.tier}</FooterItem>

        {!wasAutoLogin && (
          <SignInOrOutButton
            label={<FormattedMessage defaultMessage="Sign out" />}
            onClick={() => setPage('signOut')}
          />
        )}
      </ModalFooter>

      <AuraPointsExpiry user={user} />

      <LinkAuraBanner setPage={setPage} />
    </Container>
  );
};

AuraUserCard.propTypes = {
  setPage: PropTypes.func,
  user: PropTypes.shape({
    points: PropTypes.number,
    accountNo: PropTypes.string,
    tier: PropTypes.string,
    firstName: PropTypes.string,
  }),
};

export default AuraUserCard;
