import styled from 'styled-components';

import { Input } from 'components/PromotionCoupon/styles';
import ActionButton from 'components/ActionButton';
import { Container } from '../EarnableLoyaltyPoints/styles';

export const PanelContainer = styled(Container)<{ collapsable?: boolean }>`
  margin-block-start: 0;

  strong {
    color: ${({ theme }) => theme.color.success};
  }

  ${({ collapsable }) => {
    if (!collapsable) {
      return `border: none;`;
    }
  }};
`;

export const Title = styled.a`
  text-decoration: none;
  font-weight: bold;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Content = styled.div`
  display: none;

  &.show {
    display: block;
  }

  p {
    padding: 0.5rem 0;
  }
`;

export const StyledInput = styled(Input)`
  background-color: white;
  width: 100% !important;
  color: #5e6d70;
  padding-top: 9px;
`;

export const StyledPointsList = styled.ul`
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.color.grayLighter};
  list-style: none;
  padding: 0.5rem;
  margin: 1rem 0;
  text-align: center;
`;

export const CheckPointsValueContainer = styled.div`
  margin-top: 1rem;

  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  gap-bottom: 0;
  align-items: center;
  justify-content: space-between;

  h4 {
    flex-basis: 100%;
  }
`;

export const ApplyPointsButton = styled(ActionButton)`
  font-size: 0.85rem;
  min-height: 39px;
  padding: 0;
`;

export const AppliedPointsContainer = styled.div`
  margin-top: 1rem;

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
`;

export const PointsApplied = styled.div`
  padding: 0.5rem 0;
  max-width: 75%;
`;
