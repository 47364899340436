export const SET_LOYALTY_USER = 'app/loyalty/SET_LOYALTY_USER';
export const SET_LOYALTY_SPEND = 'app/loyalty/SET_LOYALTY_SPEND';
export const SET_LOYALTY_LINK_POSSIBLE = 'app/loyalty/LOYALTY_LINK_POSSIBLE';
export const SET_OTP_VERIFIED = 'app/loyalty/SET_OTP_VERIFIED';
export const SET_ORDER_POINTS_VALUE = 'app/loyalty/SET_ORDER_POINTS_VALUE';

export const CAPABILITY_CALCULATE_POINTS = 'CanCalculatePoints';
export const CAPABILITY_GET_OFFERS = 'CanGetOffers';
export const CAPABILITY_SIGN_UP = 'CanSignUp';
export const CAPABILITY_CAN_LINK_ACCOUNT = 'CanLinkAccount';
