import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'lodash';

import { selectLoyaltyOtpVerified } from 'selectors/loyalty';
import { setLoyaltySpend } from 'actions/loyalty';
import { User } from 'hooks/useLoyalty';
import FormattedPrice from 'components/FormattedPrice';
import OTPForm from 'components/Alert/alerts/LoyaltyModal/Aura/OTPForm';
import { ErrorMessage } from 'assets/styles/sharedStyles';
import fetchHelper from 'utils/fetchHelper';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { ChevronDown, ChevronUp } from 'components/Icons';

import { Logo } from '../EarnableLoyaltyPoints';
import {
  AppliedPointsContainer,
  ApplyPointsButton,
  CheckPointsValueContainer,
  PanelContainer,
  PointsApplied,
  StyledInput,
  Title,
  Content,
} from './aura-styles';
import AuraPointsExpiry from './AuraPointsExpiry';

interface AuraProps {
  user: User;
  value?: string;
  onChange: Function;
  collapsable: boolean;
  orderTotal?: number;
}

const Aura: React.FC<AuraProps> = ({ user, value, onChange, collapsable, orderTotal }) => {
  const [visible, setShow] = useState(false);
  const dispatch = useDispatch();

  const otpAlreadyVerified = useSelector(selectLoyaltyOtpVerified);
  const [otpVerified, setOtpVerified] = useState(false);

  const [error, setError] = useState<string>('');
  const availablePoints = user?.points || 0;

  const [pointsToSpend, setPointsToSpend] = useState(parseInt(value, 10));
  const [applied, setApplied] = useState(false);

  const [isCalculating, setIsCalculating] = useState(false);
  const [currencyValue, setCurrencyValue] = useState(0);

  const handleClick = () => {
    if (pointsToSpend > availablePoints) {
      setError("You can't redeem more points than you have");
    } else if (pointsToSpend <= 0) {
      setError('You need to enter a number of points to redeem');
    } else if (currencyValue > orderTotal) {
      setError("You can't spend more points than the order total");
    } else {
      setError('');
      // dispatch(setLoyaltySpend(pointsToSpend));
      dispatch(setLoyaltySpend(currencyValue));
      onChange(currencyValue);
      setApplied(true);
    }
  };

  const handleRemove = () => {
    setPointsToSpend(0);
    setCurrencyValue(0);
    onChange(0);
    dispatch(setLoyaltySpend(0));
    setApplied(false);
  };

  const debouncedApiRequest = useCallback(
    debounce(async () => {
      setIsCalculating(true);

      const res = await fetchHelper<{ moneyAmount: number }>(`/api/loyalty/points-to-currency`, 'POST', {
        points: pointsToSpend,
        max: orderTotal,
      });

      if (res && 'moneyAmount' in res) {
        setCurrencyValue(res.moneyAmount);
      }

      setIsCalculating(false);
    }, 300),
    [pointsToSpend, orderTotal]
  );

  useEffect(() => {
    if (pointsToSpend > 0 && orderTotal > 0) {
      debouncedApiRequest();
    }

    return () => {
      debouncedApiRequest.cancel();
    };
  }, [pointsToSpend, orderTotal, debouncedApiRequest]);

  useEffect(() => {
    const convertCurrencyToPoints = async () => {
      const res = await fetchHelper<{ points: number }>(`/api/loyalty/currency-to-points`, 'POST', {
        amount: orderTotal,
      });

      if (res && 'points' in res && res.points <= availablePoints) {
        setPointsToSpend(res.points);
        setCurrencyValue(orderTotal);
      } else {
        const res = await fetchHelper<{ moneyAmount: number }>(`/api/loyalty/points-to-currency`, 'POST', {
          points: availablePoints,
        });

        if (res && 'moneyAmount' in res) {
          setCurrencyValue(res.moneyAmount);
          setPointsToSpend(availablePoints);
        }
      }
    };

    if (!collapsable) {
      convertCurrencyToPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTotal]);

  return (
    <PanelContainer>
      {collapsable && (
        <Title onClick={() => setShow(!visible)}>
          <Logo />
          <div style={{ flexGrow: 1 }}>
            <FormattedMessage defaultMessage="Redeem your points" />
          </div>
          <div className="chevron">
            {!visible ? (
              <ChevronDown width="1.5rem" height="1.5rem" />
            ) : (
              <ChevronUp width="1.5rem" height="1.5rem" />
            )}
          </div>
        </Title>
      )}

      <Content className={visible || !collapsable ? 'show' : ''}>
        {!user.autoLogin && !otpVerified && !otpAlreadyVerified && (
          <>
            <p>
              <FormattedMessage defaultMessage="In order to redeem your points, you'll need to confirm your phone number" />
            </p>
            <OTPForm defaultMode="send-otp" onSuccess={() => setOtpVerified(true)} />
          </>
        )}

        {(user.autoLogin || otpVerified || otpAlreadyVerified) && (
          <>
            <AuraPointsExpiry user={user} />

            <FormattedMessage
              defaultMessage="You have <b>{points}</b> points"
              values={{
                points: user.points,
                b: chunks => <strong>{chunks}</strong>,
              }}
            />

            {!applied && (
              <CheckPointsValueContainer>
                <h4>
                  <FormattedMessage defaultMessage="Check your points value" />
                </h4>

                <div style={{ flexBasis: '29%' }}>
                  <StyledInput
                    name="aura-spend"
                    type="number"
                    min={0}
                    disabled={applied}
                    value={pointsToSpend || ''}
                    onChange={e => {
                      setError('');
                      const points = parseInt(e.target.value, 10);
                      setPointsToSpend(Number.isNaN(points) ? 0 : points);
                    }}
                  />
                </div>
                <div style={{ flexBasis: '5%' }}>=</div>
                <div style={{ flexBasis: '23%' }}>
                  {isCalculating ? (
                    <LoadingSpinner width="39px" height="39px" noPad={true} />
                  ) : (
                    <FormattedPrice value={currencyValue} />
                  )}
                </div>
                <div style={{ flexBasis: '30%' }}>
                  <ApplyPointsButton
                    onClick={handleClick}
                    buttonType="button"
                    disabled={!pointsToSpend}
                    label={<FormattedMessage defaultMessage="Use Points" />}
                  />
                </div>
              </CheckPointsValueContainer>
            )}

            {applied && pointsToSpend > 0 && (
              <AppliedPointsContainer>
                <PointsApplied>
                  <FormattedMessage
                    defaultMessage="<b>{points} points</b> worth <b>{total}</b> will be redeemed for this purchase"
                    values={{
                      b: chunks => <strong>{chunks}</strong>,
                      points: pointsToSpend,
                      total: <FormattedPrice value={currencyValue} />,
                    }}
                  />
                </PointsApplied>

                <div style={{ flexGrow: 1 }}>
                  <ApplyPointsButton
                    onClick={handleRemove}
                    buttonType="button"
                    label={<FormattedMessage defaultMessage="remove" />}
                  />
                </div>
              </AppliedPointsContainer>
            )}

            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Content>
    </PanelContainer>
  );
};

export default Aura;
