import AuraLogo from 'components/Icons/AuraLogo';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.borderColor};
  border-radius: 10px;
  margin-block-start: 20px;
  padding: 1rem;
  width: 100%;
  @media (max-width: 475px) {
    padding: 0.5rem;
  }
`;

export const InfoContainer = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.grayLighter};
  border-radius: 5px;
  color: black;
  display: flex;
  gap: 0.5rem;
  min-height: 3rem;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 0.5rem;
`;

export const StyledLogo = styled(AuraLogo)`
  height: 28px;
  width: auto;
`;

export const StyledLogoWrapper = styled.div`
  flex-shrink: 0;
  width: fit-content;
`;

export const LinkText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledText = styled.span`
  margin-left: 10px;
  flex-grow: 1;
  font-size: 0.8rem;
  white-space: wrap;

  @media (max-width: 400px) {
    margin-left: unset;
    white-space: unset;
  }
`;

export const RewardText = styled.span`
  color: ${({ theme }) => theme.color.success};
`;

export const PointsRemaining = styled.div`
  color: ${({ theme }) => theme.color.borderColor};
  font-size: 0.75rem;
  text-transform: uppercase;
`;

export const NoFundsLeft = styled.span`
  color: red;
`;

export const Question = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.color.success};
  width: 18px;
  height: 18px;
  font-size: 0.8rem;
  border-radius: 100%;
  color: ${({ theme }) => theme.color.white};
  text-align: center;
`;
