import 'isomorphic-fetch';

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';
type ResponseType = { error?: string; success?: boolean };

const fetchData = <T extends object>(
  url: string,
  methodType: MethodType,
  body?: object,
  success: Function = () => {},
  fail: Function = () => {}
): Promise<void | ResponseType | T> =>
  fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: methodType,
    body: body && JSON.stringify(body),
  })
    .then((response: Response) => {
      if (
        (response.status >= 200 && response.status < 300) ||
        [400, 401, 403, 404, 429].indexOf(response.status) !== -1
      ) {
        return response;
      }

      throw Error(`"${methodType} ${url}" ${response.status}`);
    })
    .then((response: Response) => {
      const isJson = response.headers.get('Content-Type')?.indexOf('application/json') !== -1;
      if (isJson) {
        return response.json();
      }

      if (!isJson && response.status === 403) {
        return { success: false, error: 'Authentication required.' };
      }

      if (!isJson && response.status === 404) {
        return { success: false, error: '404 Not Found' };
      }

      return response.text();
    })
    .catch((error: Error) => {
      // @ts-ignore
      if (typeof Sentry !== 'undefined') {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        Sentry.captureException(error);
      }

      return { error: error.message };
    })
    .then((response: ResponseType) => {
      if (response.error) {
        fail({
          ...response,
          success: false,
          error: response.error,
        });
      } else success(response);

      return response;
    });

export default fetchData;
