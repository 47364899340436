import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import fetchData from 'utils/fetchHelper';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { SET_OTP_VERIFIED } from 'actions/loyalty/constants';
import { messages } from './OTPMessages';

import { StyledActionButton, StyledInput } from './styles';
import { type ErrorContext } from './SendOTP';

type VerifyOTPProps = {
  phoneNumber: string;
  onSuccess: Function;
};

const VerifyOTP: React.FC<VerifyOTPProps> = ({ phoneNumber, onSuccess }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [verifyError, setVerifyError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorContext, setErrorContext] = useState<ErrorContext | null>(null);

  const verifyOtpHandler = (response: { success: boolean; context?: ErrorContext }) => {
    if (response.success) {
      // Don't need to set submitting as onSuccess might unmount the component
      // setSubmitting(false);
      dispatch({ type: SET_OTP_VERIFIED, payload: true });
      onSuccess();
    } else {
      setSubmitting(false);
      setVerifyError(true);
      setErrorContext(response.context);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitting(true);

    fetchData(
      `/api/loyalty/aura/verify-otp`,
      'POST',
      {
        code: parseInt(otp, 10),
      },
      verifyOtpHandler,
      verifyOtpHandler
    );
  };

  const { code, attempts_remaining: attemptsRemaining } = errorContext || {};
  const errorMessage = messages[code] || messages.OTP_UNKNOWN_ERROR;

  return (
    <>
      <p>
        <FormattedMessage
          defaultMessage="A One Time Password (OTP) has been sent to your mobile number {phoneNumber}"
          values={{
            phoneNumber,
          }}
        />
      </p>

      <StyledInput
        label="OTP"
        value={otp}
        onChange={setOtp}
        error={verifyError}
        name="otp"
        type="text"
        helpBlock={verifyError && <FormattedMessage {...errorMessage} values={{ attemptsRemaining }} />}
      />

      <StyledActionButton
        disabled={otp.length < 4}
        onClick={handleSubmit}
        label={
          submitting ? (
            <LoadingSpinner noPad={true} height="1.5rem" width="1.5rem" />
          ) : (
            <FormattedMessage defaultMessage="Confirm" />
          )
        }
      />
    </>
  );
};

export default VerifyOTP;
