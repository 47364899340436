import { fetchHelper, getErrorsList, getUserLocation } from 'utils';

import { setDetail, removeDetail } from 'actions/storage';
import { getLocale, isLoggedIn, selectIsUserAddressInRange, selectAddressComponents } from 'selectors/user';
import {
  openLoggedOutAlert,
  openLoginRegisterAlert,
  openConfirmLogoutAlert,
  openDeletePaymentSourceErrorAlert,
} from 'actions/UI';
import { selectVenueDefaultLocale } from 'selectors/root';
import { setLoyaltyUser } from 'actions/loyalty';
import { getValidateResetToken } from '../../../shared/dataLoader';
import * as c from './constants';
import { trackEvent, setUserProperties } from '../../utils/tracking';

export const login = user => ({
  type: c.LOGIN,
  user,
  syncOrder: true,
});

export const setUser = user => ({
  type: c.SET_USER,
  user,
});

export const updateUser = userDetails => ({
  type: c.UPDATE_USER,
  userDetails,
});

export const confirmLogout = () => dispatch => {
  const confirm = () => {
    trackEvent('logout', { category: 'settings' });
    dispatch(logout());
    dispatch(setLoyaltyUser());
  };

  dispatch(openConfirmLogoutAlert(confirm));
};

export const forceLogout = () => (dispatch, getState) => {
  const userWasLoggedIn = isLoggedIn(getState());
  dispatch({ type: c.LOGOUT });

  const confirm = () => {
    dispatch(openLoginRegisterAlert());
  };

  if (userWasLoggedIn) dispatch(openLoggedOutAlert(confirm));
};

export const logout =
  (broadcastedEvent = false) =>
  dispatch => {
    fetchHelper('/api/logout', 'POST');
    dispatch({
      type: c.LOGOUT,
      syncOrder: true,
      broadcastedEvent,
    });
  };

export const setLocale = (locale, refresh = true) => ({
  type: c.SET_LOCALE,
  refreshLocale: refresh,
  locale,
});

export const resetLocale = () => (dispatch, getState) =>
  dispatch(setLocale(selectVenueDefaultLocale(getState(), false)));

export const addFavourite = product => (dispatch, getState) => {
  if (isLoggedIn(getState())) {
    dispatch({
      type: c.ADD_FAVOURITE,
      product,
    });
  } else {
    dispatch(openLoginRegisterAlert());
  }
};

export const removeFavourite = productId => ({
  type: c.REMOVE_FAVOURITE,
  productId,
});

const requestOrders = () => ({
  type: c.REQUEST_ORDERS,
});

const receiveOrders = (orders, venues) => ({
  type: c.RECEIVE_ORDERS,
  orders,
  venues,
});

const fetchOrderData = url =>
  new Promise((resolve, reject) => {
    const success = res => {
      if (res.data) resolve(res.data);
    };
    const fail = res => reject(res);
    fetchHelper(url, 'GET', null, success, fail);
  });

export const fetchOrders = () => dispatch => {
  dispatch(requestOrders());

  Promise.all([fetchOrderData('/api/users/orders'), fetchOrderData('/api/users/orders/venues')])
    .then(([ordersList, venueList]) => {
      dispatch(receiveOrders(ordersList, venueList));
    })
    .catch(() => {}); // Probably a 403 or something, catch the error and don't update state
};

export const setOffline = () => ({
  type: c.SET_OFFLINE,
});

export const setOnline = () => ({
  type: c.SET_ONLINE,
});

const setUserGeoLocation = location => ({
  type: c.SET_USER_GEO_LOCATION,
  location,
});

export const loadingUserGeoLocation = () => ({
  type: c.LOADING_USER_GEO_LOCATION,
});

export const loadingUserAddressFromCoords = () => ({
  type: c.LOADING_USER_ADDRESS_FROM_COORDS,
});

export const errorUserGeoLocation = error => ({
  type: c.ERROR_USER_GEO_LOCATION,
  error,
});

export const removeUserGeoLocation = () => ({
  type: c.REMOVE_USER_GEO_LOCATION,
});

export const clearPredictionsAndCoordsFromSession = () => ({
  type: c.CLEAR_USER_PREDICTIONS_AND_COORDS_SESSION,
});

export const loadingUserPredictions = () => ({
  type: c.LOADING_USER_PREDICTIONS,
});

const setUserPredictions = predictions => ({
  type: c.SET_USER_PREDICTIONS,
  predictions,
});

export const errorUserPredictions = error => ({
  type: c.ERROR_USER_PREDICTIONS,
  error,
});

export const loadingUserCoords = () => ({
  type: c.LOADING_USER_COORDS,
});

const setUserUserCoords = (coords, description, addressComponents) => ({
  type: c.SET_USER_COORDS,
  coords,
  description,
  addressComponents,
});

export const errorUserCoords = error => ({
  type: c.ERROR_USER_COORDS,
  error,
});

export const clearPredictionsAndCoords = () => ({
  type: c.CLEAR_USER_PREDICTIONS_AND_COORDS,
});

export const clearPredictionsAndCoordsFromStateAndSession = () => (dispatch, getState) => {
  const addressComponents = selectAddressComponents(getState())?.toJS();

  dispatch(loadingUserPredictions());
  dispatch(clearPredictionsAndCoords());

  const complete = () => {
    dispatch(clearPredictionsAndCoordsFromSession());
    dispatch(removeDetail('authorizedGeoLocation'));

    if (addressComponents) {
      Object.keys(addressComponents).forEach(key => dispatch(removeDetail(key)));
    }
  };

  fetchHelper(`/api/users/location/predictions/clear`, 'GET', null, complete, complete);
};

export const requestUserLocation = callback => dispatch => {
  dispatch(loadingUserGeoLocation());

  const success = coords => {
    dispatch(setUserGeoLocation(coords));

    const { latitude, longitude } = coords;

    dispatch(getUserAddressFromCoords(latitude, longitude, callback));
  };

  const error = err => {
    dispatch(errorUserGeoLocation([{ message: err }]));
  };

  getUserLocation(success, error);
};

const getUserAddressFromCoords = (latitude, longitude, callback) => (dispatch, getState) => {
  if (!latitude || !longitude) return;

  dispatch(loadingUserAddressFromCoords());

  const success = response => {
    if (response?.data) {
      const expiry = { format: 'hours', length: 12 };
      const { coords, description, addressComponents } = response.data;

      dispatch(setUserUserCoords(coords, description, addressComponents));

      dispatch(setDetail('authorizedGeoLocation', 'true', expiry));
      Object.keys(addressComponents).forEach(key => dispatch(setDetail(key, addressComponents[key], expiry)));

      if (selectIsUserAddressInRange(getState())) {
        callback();
      }
    } else {
      dispatch(errorUserGeoLocation([{ message: 'No Results found' }]));
    }
  };

  const fail = res => dispatch(errorUserGeoLocation([{ message: res.error }]));

  fetchHelper(`/api/users/location/addressFromCoords/${latitude}/${longitude}`, 'GET', null, success, fail);
};

export const fetchUserPostcodeLocation = postcode => dispatch => {
  if (!postcode || postcode === '')
    return dispatch(errorUserGeoLocation([{ message: 'Please enter a postcode' }]));

  dispatch(loadingUserGeoLocation());

  const success = response => {
    if (
      response.data &&
      response.data[0] &&
      response.data[0].geometry &&
      response.data[0].geometry.location
    ) {
      const { lat: latitude, lng: longitude } = response.data[0].geometry.location;
      dispatch(setUserGeoLocation({ latitude, longitude, postcode }));
    } else {
      dispatch(errorUserGeoLocation([{ message: 'No Results found' }]));
    }
  };

  const fail = res => dispatch(errorUserGeoLocation([{ message: res.error }]));

  fetchHelper(`/api/users/location/${postcode}`, 'GET', null, success, fail);
};

export const getUserPredictions = value => dispatch => {
  if (!value || value === '') return dispatch(errorUserPredictions([{ message: 'Please enter an address' }]));

  dispatch(loadingUserPredictions());

  const success = response => {
    if (response.predictions?.length > 0) {
      dispatch(setUserPredictions(response.predictions));
    } else {
      dispatch(errorUserPredictions([{ message: 'No Results found' }]));
    }
  };

  const fail = res => dispatch(errorUserPredictions([{ message: res.error }]));

  fetchHelper(`/api/users/location/predictions/${value}`, 'GET', null, success, fail);
};

export const getUserCoordsFromPrediction = (placeId, callback) => (dispatch, getState) => {
  dispatch(loadingUserCoords());

  const success = response => {
    if (response.data) {
      const expiry = { format: 'hours', length: 12 };
      const { coords, description, addressComponents } = response.data;

      dispatch(setUserUserCoords(coords, description, addressComponents));
      Object.keys(addressComponents).forEach(key => dispatch(setDetail(key, addressComponents[key], expiry)));

      if (selectIsUserAddressInRange(getState())) {
        callback();
      }
    } else {
      dispatch(errorUserCoords([{ message: 'No coordinates found' }]));
    }
  };

  const fail = res => dispatch(errorUserCoords([{ message: res.error }]));

  fetchHelper(`/api/users/location/coordsFromPrediction/${placeId}`, 'GET', null, success, fail);
};

export const loginRequestStart = (email, password, callback) => (dispatch, getState) => {
  dispatch({ type: c.LOGIN_REQUEST_START });

  const success = ({ user, loyaltyUser }) => {
    const newState = getState();
    const locale = getLocale(newState);
    const userDefaultLocale = user.default_locale;

    dispatch({ type: c.LOGIN_REQUEST_SUCCESSFUL });
    dispatch(login(user));
    callback({ success: true, user });
    trackEvent('login', { category: 'settings' });
    setUserProperties({
      user_id: user.id,
      default_locale: user.default_locale,
      is_admin: user.isAdmin,
      is_staff: user.isStaff,
    });

    if (userDefaultLocale && userDefaultLocale !== locale) {
      dispatch(setLocale(userDefaultLocale));
    }

    if (loyaltyUser) {
      dispatch(setLoyaltyUser(loyaltyUser));
    }
  };

  const fail = () => {
    dispatch({ type: c.LOGIN_REQUEST_FAILED });
    callback({ success: false });
  };

  fetchHelper('/api/login', 'POST', { email, password }, success, fail);
};

export const loginClearState = () => ({
  type: c.LOGIN_CLEAR_STATE,
});

export const registerRequestStart = (name, email, telephone, password, callback) => dispatch => {
  dispatch({ type: c.REGISTER_REQUEST_START });

  const success = ({ user }) => {
    trackEvent('register_new_user', {
      category: 'settings',
    });
    dispatch({ type: c.REGISTER_REQUEST_SUCCESSFUL });
    dispatch(login(user));
    callback({ success: true });
  };

  const fail = ({ validation }) => {
    dispatch({
      type: c.REGISTER_REQUEST_FAILED,
      validation: getErrorsList(validation),
    });
    callback({ success: false });
  };

  fetchHelper('/api/register', 'POST', { name, email, telephone, password }, success, fail);
};

export const registerClearState = () => ({
  type: c.REGISTER_CLEAR_STATE,
});

export const initiateForgotPassword = (email, callback) => dispatch => {
  dispatch({
    type: c.INITIATE_FORGOT_PASSWORD,
    email,
  });

  const complete = res => {
    dispatch({
      type: c.FORGOT_PASSWORD_REQUEST_COMPLETE,
      success: res.success,
      violations: res.success && res.violations ? res.violations : null,
    });
    callback(res);
  };

  fetchHelper('/api/password_reset', 'POST', { email }, complete, complete);
};

export const forgotPasswordClearState = () => ({
  type: c.FORGOT_PASSWORD_CLEAR_STATE,
});

export const initiateResetPassword = (password, callback) => dispatch => {
  dispatch({
    type: c.INITIATE_RESET_PASSWORD,
  });

  const complete = ({ success, errors }) => {
    dispatch({
      type: c.RESET_PASSWORD_REQUEST_COMPLETE,
      success,
      violations: success === false ? getErrorsList(errors) : [],
    });
    callback({ success, errors });
  };

  fetchHelper('/api/password_reset/reset', 'POST', { password }, complete, complete);
};

export const validateResetToken = token => dispatch => {
  dispatch({
    type: c.VALIDATE_RESET_TOKEN_REQUEST,
  });

  return getValidateResetToken(token).then(response =>
    dispatch({
      type: c.VALIDATE_RESET_TOKEN_COMPLETE,
      email: response.email,
      valid: response.valid,
    })
  );
};

export const setSelectedVenue = venueId => ({
  type: c.SET_SELECTED_VENUE,
  venueId,
});

export const toggleFilter = venueId => ({
  type: c.TOGGLE_FILTER,
  venueId,
});

export const clearFilter = venueId => ({
  type: c.CLEAR_FILTER,
  venueId,
});

export const applyFilter = (venueId, from, to) => ({
  type: c.APPLY_FILTER,
  venueId,
  from,
  to,
});

export const getPaymentSources =
  (callback = () => {}) =>
  dispatch => {
    const success = res => {
      dispatch({
        type: c.SET_PAYMENT_SOURCES,
        paymentSources: res,
      });
      callback({ success: true });
    };
    const fail = () => callback({ success: false });

    fetchHelper('/api/user/payment-sources', 'GET', null, success, fail);
  };

export const deletePaymentSource = (id, name) => dispatch => {
  const success = () => {
    dispatch({
      type: c.DELETE_PAYMENT_SOURCE,
      paymentSourceId: id,
    });
  };

  const fail = dispatch(openDeletePaymentSourceErrorAlert(name));

  fetchHelper(`/api/user/payment-sources/${id}`, 'DELETE', null, success, fail);
};

export const addPaymentSource = callback => () => {
  const success = res => callback({ success: true, iframeUrl: res?.iframeUrl });
  const fail = () => callback({ success: false });

  fetchHelper('/api/user/payment-sources', 'POST', null, success, fail);
};

export const exportOrderRequestStart = (email, orderReference, callback) => dispatch => {
  dispatch({ type: c.EXPORT_ORDER_REQUEST_START });

  const success = () => {
    dispatch({ type: c.EXPORT_ORDER_REQUEST_SUCCESSFUL });
    callback({ success: true });
  };

  const fail = () => {
    dispatch({ type: c.EXPORT_ORDER_REQUEST_FAILED });
    callback({ success: false });
  };

  fetchHelper(`/api/users/me/orders/${orderReference}/export`, 'POST', { email }, success, fail);
};

export const getAddresses = () => dispatch => {
  dispatch({ type: c.REQUEST_ADDRESSES_START });

  const success = addressesList => {
    dispatch({ type: c.REQUEST_ADDRESSES_SUCCESS, addressesList });
  };

  const fail = () => {
    dispatch({ type: c.REQUEST_ADDRESSES_FAILURE });
  };

  fetchHelper(`/api/users/me/addresses`, 'GET', null, success, fail);
};

export const addAddress =
  (address, callback = () => {}) =>
  dispatch => {
    dispatch({ type: c.ADD_ADDRESS_START });

    const success = newAddress => {
      dispatch({ type: c.ADD_ADDRESS_SUCCESS, newAddress });
      callback({ success: true });
    };

    const fail = () => {
      dispatch({ type: c.ADD_ADDRESS_FAILURE });
      callback({ success: false });
    };

    fetchHelper(`/api/users/me/addresses`, 'POST', { address }, success, fail);
  };

export const editAddress =
  (address, addressId, callback = () => {}) =>
  dispatch => {
    dispatch({ type: c.EDIT_ADDRESS_START });

    const success = editedAddress => {
      dispatch({ type: c.EDIT_ADDRESS_SUCCESS, editedAddress });
      callback({ success: true });
    };

    const fail = () => {
      dispatch({ type: c.EDIT_ADDRESS_FAILURE });
      callback({ success: false });
    };

    fetchHelper(`/api/users/me/addresses/${addressId}`, 'PUT', { address }, success, fail);
  };

export const deleteAddress = addressId => dispatch => {
  dispatch({ type: c.DELETE_ADDRESS_START });

  const success = () => {
    dispatch({ type: c.DELETE_ADDRESS_SUCCESS, addressId });
  };

  const fail = () => {
    dispatch({ type: c.DELETE_ADDRESS_FAILURE });
  };

  fetchHelper(`/api/users/me/addresses/${addressId}`, 'DELETE', {}, success, fail);
};

export const setDefaultAddress = addressId => dispatch => {
  dispatch({ type: c.SET_DEFAULT_ADDRESS_START });

  const success = () => {
    dispatch({ type: c.SET_DEFAULT_ADDRESS_SUCCESS, addressId });
  };

  const fail = () => {
    dispatch({ type: c.SET_DEFAULT_ADDRESS_FAILURE });
  };

  fetchHelper(`/api/users/me/addresses/default/${addressId}`, 'POST', {}, success, fail);
};

export const exportOrders =
  (dateFrom, dateTo, email, callback = () => {}) =>
  dispatch => {
    dispatch({ type: c.EXPORT_ORDERS_START });

    const success = () => {
      dispatch({ type: c.EXPORT_ORDERS_SUCCESS });
      callback({ success: true });
    };

    const fail = () => {
      dispatch({ type: c.EXPORT_ORDERS_FAILURE });
      callback({ success: false });
    };

    fetchHelper(`/api/users/me/orders/export`, 'POST', { dateFrom, dateTo, email }, success, fail);
  };
