import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ActionButton from 'components/ActionButton';
import { useSelector } from 'react-redux';
import { selectLoyaltyLinkPossible, selectLoyaltyLinkReason } from 'selectors/loyalty';

const Container = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  font-size: 0.9rem;
  background-color: ${({ theme }) => theme.color.grayLighter};
  border-radius: 1rem;

  div {
    text-align: center;
    padding-top: 0.5rem;
  }

  button {
    display: inline-block;
  }
`;

const LinkButton = styled(ActionButton)`
  width: 180px;
  background-color: ${({ theme }) => theme.buttons.primaryBg};
  color: ${({ theme }) => theme.buttons.primaryColor};
  border: 1px solid ${({ theme }) => theme.buttons.primaryBorder};
`;

const messages = {
  NOT_POSSIBLE: <FormattedMessage defaultMessage="Sorry, you cannot link your Aura account at this time." />,
  EMAIL_MISMATCH: <FormattedMessage defaultMessage="Would you like to link your Aura account?" />,
  MATCHING_USER_FOUND: (
    <FormattedMessage defaultMessage="An Aura loyalty card is already associated with your email address. Do you want to link now?" />
  ),
};

type LinkAuraBannerProps = {
  setPage: (page: string) => void;
};

const LinkAuraBanner: React.FC<LinkAuraBannerProps> = ({ setPage }) => {
  const canLinkAccount = useSelector<boolean>(selectLoyaltyLinkPossible);
  const linkReason: string = useSelector<string>(selectLoyaltyLinkReason);

  if (!canLinkAccount) {
    return null;
  }

  const linkReasonMessage = messages[linkReason] || null;

  return (
    <Container>
      <h4>{linkReasonMessage}</h4>
      <div>
        <LinkButton
          label={<FormattedMessage defaultMessage="Link your Aura" />}
          onClick={() => setPage('linkAccount')}
        />
      </div>
    </Container>
  );
};

export default LinkAuraBanner;
