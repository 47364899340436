import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLoyaltyLinkDetails, selectLoyaltyUser } from 'selectors/loyalty';
import SendOTP from './SendOTP';
import VerifyOTP from './VerifyOTP';

type Mode = 'send-otp' | 'verify-otp';

type OTPFormProps = {
  defaultMode?: Mode;
  onSuccess: () => void;
};

const OTPForm: React.FC<OTPFormProps> = ({ defaultMode, onSuccess }) => {
  const [mode, setMode] = useState<Mode>(defaultMode);
  const loyaltyUser = useSelector(selectLoyaltyUser);
  const linkDetails = useSelector(selectLoyaltyLinkDetails);

  const phoneNumber = linkDetails?.get('phoneNumber') || loyaltyUser.get('mobilePhone');

  return (
    <>
      {mode === 'send-otp' && <SendOTP phoneNumber={phoneNumber} onSend={() => setMode('verify-otp')} />}

      {mode === 'verify-otp' && <VerifyOTP phoneNumber={phoneNumber} onSuccess={onSuccess} />}
    </>
  );
};

OTPForm.defaultProps = {
  defaultMode: 'send-otp',
};

export default OTPForm;
