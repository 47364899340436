import React, { forwardRef, ReactNode, MouseEventHandler } from 'react';
import { Container, StyledAnchor, StyledButton, StyledLink } from './styles';

interface IProps {
  dataCy?: string;
  to?: string;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  buttonIcon?: JSX.Element;
  label: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  buttonType?: string;
  primaryButton?: boolean;
  dangerButton?: boolean;
  className?: string;
  forceReload?: boolean;
  form?: string;
}

const ActionButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IProps>((props, ref) => {
  const {
    className,
    dataCy,
    label,
    buttonIcon = null,
    onClick,
    to,
    disabled = false,
    loading = false,
    buttonType,
    primaryButton = false,
    dangerButton = false,
    forceReload = false,
    form = undefined,
  } = props;

  const Link = forceReload ? StyledAnchor : StyledLink;
  const Component = to ? Link : StyledButton;

  return (
    <Component
      to={to}
      href={forceReload ? to : undefined}
      ref={to ? undefined : ref}
      innerRef={to ? ref : undefined}
      className={className}
      data-cy={dataCy}
      primary={primaryButton ? 1 : undefined}
      danger={dangerButton ? 1 : undefined}
      onClick={onClick}
      disabled={disabled}
      loading={loading ? 1 : undefined}
      type={buttonType}
      form={form}
    >
      <Container>
        {buttonIcon}
        {label}
      </Container>
    </Component>
  );
});

export default ActionButton;
