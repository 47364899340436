import React, { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';

import RedeemPoints from 'components/Loyalty/RedeemLoyaltyPoints';

export default function RedeemLoyaltyPoints() {
  const [value, setValue] = useState<number>(undefined);

  const params = new URLSearchParams(window.location.search);
  const orderTotal = parseInt(params.get('total'), 10);

  const onChange = (points: number) => {
    setValue(points);
    window.parent.postMessage(
      {
        type: 'set-loyalty-spend',
        value: points,
      },
      '*'
    );
  };

  useEffect(() => {
    const resizeOberver = new ResizeObserver(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage(
        {
          type: 'set-loyalty-height',
          value: height,
        },
        '*'
      );
    });

    resizeOberver.observe(document.body);

    return () => {
      resizeOberver.disconnect();
    };
  }, []);

  // Without this we have small little coloured corners
  const GlobalStyle = createGlobalStyle`
    .clientBackground {
      background-color: transparent;
    }
  `;

  return (
    <div>
      <GlobalStyle />
      <RedeemPoints value={value?.toString()} onChange={onChange} orderTotal={orderTotal} />
    </div>
  );
}
