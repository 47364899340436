import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import LoadingSpinner from 'components/Loading/LoadingSpinner';
import fetchData from 'utils/fetchHelper';
import { ErrorMessage, StyledActionButton } from './styles';
import { messages } from './OTPMessages';

type SendOTPProps = {
  phoneNumber: string;
  onSend: () => void;
};

export type ErrorContext = { code: string; attempts_remaining: number };

const SendOTP: React.FC<SendOTPProps> = ({ phoneNumber, onSend }) => {
  const [submitting, setSubmitting] = useState(false);
  const [errorContext, setErrorContext] = useState<ErrorContext | null>(null);

  const sendOtpHandler = (response: { success: boolean; context?: ErrorContext }) => {
    if (response.success) {
      onSend();
    } else {
      setSubmitting(false);
      setErrorContext(response.context);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    setSubmitting(true);
    fetchData(`/api/loyalty/aura/send-otp`, 'POST', {}, sendOtpHandler, sendOtpHandler);
  };

  const { code, attempts_remaining: attemptsRemaining } = errorContext || {};
  const errorMessage = messages[code] || messages.OTP_UNKNOWN_ERROR;

  return (
    <>
      <p>
        <FormattedMessage
          defaultMessage="We'll send a One-Time Password (OTP) to your phone number {phoneNumber}"
          values={{ phoneNumber }}
        />
      </p>

      <StyledActionButton
        onClick={handleSubmit}
        disabled={submitting}
        label={
          submitting ? (
            <LoadingSpinner noPad={true} height="1.5rem" width="1.5rem" />
          ) : (
            <FormattedMessage defaultMessage="Send OTP" />
          )
        }
      />

      {errorContext && (
        <>
          <ErrorMessage>
            <FormattedMessage {...errorMessage} values={{ attemptsRemaining }} />
          </ErrorMessage>
          {attemptsRemaining > 0 && (
            <ErrorMessage>
              <FormattedMessage
                defaultMessage="{attemptsRemaining} attempts remaining before lockout."
                values={{ attemptsRemaining }}
              />
            </ErrorMessage>
          )}
          {attemptsRemaining === 0 && (
            <ErrorMessage>
              <FormattedMessage
                defaultMessage="Please wait 30 minutes before trying again."
                values={{ attemptsRemaining }}
              />
            </ErrorMessage>
          )}
        </>
      )}
    </>
  );
};

export default SendOTP;
