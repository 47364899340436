import {
  CAPABILITY_CALCULATE_POINTS,
  SET_LOYALTY_LINK_POSSIBLE,
  SET_LOYALTY_SPEND,
  SET_LOYALTY_USER,
  SET_ORDER_POINTS_VALUE,
} from 'actions/loyalty/constants';
import { LOGIN } from 'actions/user/constants';
import { selectServiceId } from 'selectors/browse';
import {
  selectLoyaltyCapabilitiesByService,
  selectLoyaltySpend,
  selectLoyaltyTypeByService,
} from 'selectors/loyalty';
import { selectOrder, selectOrderTotal } from 'selectors/order';
import { fetchHelper } from 'utils';

let previousOrderTotal = 0;

const handleLoyaltyLinkPossible = (dispatch: any) => {
  const handler = (response: any) => {
    dispatch({
      type: SET_LOYALTY_LINK_POSSIBLE,
      payload: {
        possible: !!response?.result,
        reason: response?.reason,
        phoneNumber: response?.phoneNumber,
      },
    });
  };

  fetchHelper('/api/loyalty/link-possible', 'GET', handler, handler);
};

const shouldUpdatePoints = (orderTotal: number, actionType: string) =>
  orderTotal !== previousOrderTotal || actionType === SET_LOYALTY_SPEND || actionType === SET_LOYALTY_USER;

const updateOrderPointsValue = (dispatch: any, order: any, loyaltySpend: any) => {
  dispatch({ type: SET_ORDER_POINTS_VALUE, payload: { loading: true } });

  fetchHelper(
    `/api/loyalty/order-to-points`,
    'POST',
    { order: { ...order, loyaltySpend } },
    (data: any) => {
      dispatch({
        type: SET_ORDER_POINTS_VALUE,
        payload: {
          loading: false,
          points: data.points,
          error: false,
        },
      });
    },
    () => {
      dispatch({
        type: SET_ORDER_POINTS_VALUE,
        payload: {
          loading: false,
          points: 0,
          error: true,
        },
      });
    }
  );
};

const loyaltyMiddleware =
  ({ dispatch, getState }: any) =>
  (next: any) =>
  (action: any) => {
    const result = next(action);

    const state = getState();
    const serviceId = selectServiceId(state);
    const loyaltyType = selectLoyaltyTypeByService(state, serviceId);

    if (!loyaltyType) return result;

    if ([LOGIN, '@@CLIENT_INIT', SET_LOYALTY_USER].includes(action.type)) {
      handleLoyaltyLinkPossible(dispatch);
    }

    const capabilities = selectLoyaltyCapabilitiesByService(state, serviceId);
    if (capabilities?.includes(CAPABILITY_CALCULATE_POINTS)) {
      const orderTotal = selectOrderTotal(state);

      if (shouldUpdatePoints(orderTotal, action.type)) {
        const order = selectOrder(state)?.toJS();
        const loyaltySpend = selectLoyaltySpend(state);
        previousOrderTotal = orderTotal;
        updateOrderPointsValue(dispatch, order, loyaltySpend);
      }
    }

    return result;
  };

export default loyaltyMiddleware;
