import { fromJS } from 'immutable';
import {
  SET_LOYALTY_USER,
  SET_LOYALTY_SPEND,
  SET_LOYALTY_LINK_POSSIBLE,
  SET_OTP_VERIFIED,
  SET_ORDER_POINTS_VALUE,
} from 'actions/loyalty/constants';

export const initialState = fromJS({
  loyaltyTypeByService: undefined,
  user: undefined,
  link: { possible: false, reason: '', phoneNumber: '' },
  orderPointsValue: { loading: false, error: false, points: 0 }, // how many points is the current order worth?
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOYALTY_USER: {
      return state.set('user', fromJS(action.user));
    }
    case SET_LOYALTY_SPEND: {
      return state.set('loyaltySpend', action.loyaltySpend);
    }
    case SET_LOYALTY_LINK_POSSIBLE: {
      return state.set('link', fromJS(action.payload));
    }
    case SET_OTP_VERIFIED: {
      return state.set('otpVerified', action.payload);
    }
    case SET_ORDER_POINTS_VALUE: {
      return state.set('orderPointsValue', action.payload);
    }
    default:
      return state;
  }
};
