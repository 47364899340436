import React from 'react';
import { useLoyalty } from 'hooks';
import { useSelector } from 'react-redux';

import { selectOrderTotal } from 'selectors/order';
import Como from './Como';
import Aura from './Aura';

interface Props {
  value?: string;
  onChange: Function;
  // For Aura, we can specify if the panel should be collapsable
  collapsable?: boolean;
  // Optionally pass the order total so children don't need to fetch it
  orderTotal?: number;
}

const RedeemPoints: React.FC<Props> = ({ orderTotal, value, onChange, collapsable }) => {
  const { user, type } = useLoyalty();
  const storedOrderTotal = useSelector(selectOrderTotal);
  const total = orderTotal || storedOrderTotal;

  if (!user) {
    return <></>;
  }

  if (type === 'como') {
    return <Como value={value} onChange={onChange} user={user} />;
  }

  if (type === 'aura') {
    return (
      <Aura value={value} onChange={onChange} user={user} collapsable={collapsable} orderTotal={total} />
    );
  }

  return <div>Unknown Loyalty Partner</div>;
};

export default RedeemPoints;
