export const selectLoyaltyTypeByService = (state, serviceId) =>
  state.getIn(['loyalty', 'loyaltyTypeByService', serviceId?.toString(), 'type']);

export const selectLoyaltyCapabilitiesByService = (state, serviceId): Array<string> =>
  state.getIn(['loyalty', 'loyaltyTypeByService', serviceId?.toString(), 'capabilities'], []);

export const selectLoyaltyUser = state => state.getIn(['loyalty', 'user']);

export const selectLoyaltySpend = state => state.getIn(['loyalty', 'loyaltySpend']);

export const selectLoyaltyLinkDetails = state => state.getIn(['loyalty', 'link']);

export const selectLoyaltyLinkPossible = state => selectLoyaltyLinkDetails(state)?.get('possible');

export const selectLoyaltyLinkReason = state => state.getIn(['loyalty', 'link', 'reason']);

export const selectLoyaltyWasAutoLogin = state => selectLoyaltyUser(state)?.get('autoLogin');

export const selectLoyaltyOtpVerified = state => state.getIn(['loyalty', 'otpVerified']);

export const selectOrderPointsValue = state => state.getIn(['loyalty', 'orderPointsValue']);
