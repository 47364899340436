import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { PointBalance, User } from 'hooks/useLoyalty';
import { StyledPointsList } from './aura-styles';

interface AuraPointsExpiryProps {
  user: User;
}

/**
 * Show the nearest points expiring details to one month,
 * then three months: “Points expiring in the same month, if not points
 * expiring in next 3 months”
 */
export const filterExpiringPoints = (pointBalances: PointBalance[]) => {
  const sortedByExpiryDate = [...(pointBalances ?? [])].sort(
    (a: PointBalance, b: PointBalance) =>
      new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime()
  );

  const threeMonthsFromNow = Date.now() + 3 * 30 * 24 * 60 * 60 * 1000;

  return sortedByExpiryDate
    .filter(pointBalance => new Date(pointBalance.expirationDate).getTime() < threeMonthsFromNow)
    .slice(0, 1);
};

const AuraPointsExpiry: React.FC<AuraPointsExpiryProps> = ({ user }) => {
  const expiringPoints = filterExpiringPoints(user.pointBalances);

  if (expiringPoints.length === 0) {
    return null;
  }

  return (
    <StyledPointsList>
      {filterExpiringPoints(user.pointBalances).map(pointBalance => (
        <li key={pointBalance.expirationDate}>
          <FormattedMessage
            defaultMessage="{points} points will expire by <b>{expirationDate}</b>"
            values={{
              b: chunks => <strong>{chunks}</strong>,
              points: pointBalance.total,
              expirationDate: <FormattedDate value={pointBalance.expirationDate} dateStyle="medium" />,
            }}
          />
          <br />
        </li>
      ))}
    </StyledPointsList>
  );
};

export default AuraPointsExpiry;
